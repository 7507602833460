<template>
  <section class="iq-section iq-register">
    <div class="iq-register__logo">
      <img src="../assets/logod.png" alt="">
      <span>申请团长</span>
    </div>
    <div class="iq-register__form">
      <van-form @submit="submit">
        <van-field
          v-model="name"
          required
          readonly
          name="用户名"
          label="用户名："
          placeholder="用户名"
        />
        <van-field
          v-model="phone"
          required
          name="手机号"
          label="手机号："
          placeholder="手机号"
        >
        </van-field>
        <van-field
          v-model="code"
          required
          name="验证码"
          label="验证码："
          placeholder="验证码"
        >
          <template #button>
            <van-button size="small" plain type="danger" :disabled="seconds > 0" native-type="button"  @click="sendCode">{{codeText}}</van-button>
          </template>
        </van-field>
        <div style="margin: 40px 12px;">
          <van-button block type="danger" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </section>
</template>

<script>
  import {Notify, Toast} from "vant";
  import {flushUserInfo, getSms, submitDR} from "@/api/user";

  export default {
  name: 'DrRegister',
  data () {
    return {
      toastText: '',
      toastShow: '',
      name: this.$store.getters.userinfo.nickName,
      phone: '',
      code: '',
      seconds: 0,
      codeText: '获取验证码',
      submitLoading: false
    }
  },
  mounted () {},
  methods: {
    toast (str) {
      let v = this
      v.toastText = str
      v.toastShow = true
      setTimeout(function () {
        v.toastShow = false
      }, 2500)
    },
    submit() {
      if (this.phone.length != 11) {
        Notify({ type: 'warning', message: '请输入您的手机号' });
        return;
      }
      if (this.code.length <= 0) {
        Notify({ type: 'warning', message: '请输入验证码' });
        return;
      }
      this.submitLoading = true;
      submitDR({
        phone: this.phone,
        vc: this.code
      }).then(async (data) => {
        if (data.success == true) {
          Toast('申请成功');
          const res = await flushUserInfo();
          if (res.success == true) {
            this.$store.commit('SET_INFO', res.data);
            console.log(res.data)
          }
          this.$router.push('/drsmyzuce');
        } else {
          Toast(data.msg)
        }
      }).finally(() => {
        this.submitLoading =false;
      })

    },
    sendCode() {
      console.log(this.seconds)
      if (this.seconds > 0) {
        return;
      }
      if (this.phone.length <= 0) {
        Notify({ type: 'warning', message: '请输入您的手机号' });
        return;
      }
      this.seconds = 60;
      let timer = setInterval(() => {
        this.seconds--;
        this.codeText = '重新获取' + this.seconds + 's';
        if (this.seconds <= 0) {
          this.codeText = '获取验证码';
          clearInterval(timer)
        }
      }, 1000);
      getSms(this.phone).then(data => {
        if (data.success == true) {
          Toast('验证码已发送')
        } else {
          Toast('手机号错误发送失败');
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .iq-register {
    height: 100%;
    background: #fff;
    overflow: hidden;

    ::v-deep .van-cell--required:before {
      color: #fff;
    }

    ::v-deep .van-button--small {
      border-color: #fff;
    }

    &__ {

      &logo {
        border-radius: 50%;
        margin: 40px auto;
        text-align: center;

        img,
        span {
          display: block;
          line-height: 1;
        }

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin: 0 auto 8px;
        }

        span {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
</style>
